import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-lead-agenda-item',
  templateUrl: './lead-agenda-item.component.html',
  styleUrls: ['./lead-agenda-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadAgendaItemComponent extends FieldType {}

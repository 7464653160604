export function sortObjectHelper<T extends Record<string, any>[]>(
  data: T,
  sortConfig: { field: string; direction: 'asc' | 'desc' }
): T {
  const order = sortConfig.direction === 'asc' ? 1 : -1;

  return data.sort((a, b) => {
    const field = sortConfig.field;

    const valueA = a[field];
    const valueB = b[field];

    if (!isNaN(valueA) && !isNaN(valueB)) {
      return (valueA - valueB) * order;
    }

    const dateA = Date.parse(valueA);
    const dateB = Date.parse(valueB);

    if (!isNaN(dateA) && !isNaN(dateB)) {
      return (dateA - dateB) * order;
    }

    const strA = valueA?.toString().toLowerCase() || '';
    const strB = valueB?.toString().toLowerCase() || '';

    if (strA < strB) return -1 * order;
    if (strA > strB) return 1 * order;

    return 0;
  });
}

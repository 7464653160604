import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { BaseDataModule } from '@capturum/complete';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCardModule } from '@capturum/ui/card';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumProgressBarModule } from '@capturum/ui/progress-bar';
import { CapturumRadioModule } from '@capturum/ui/radio';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '@shared/layout/layout.module';
import { SharedModule } from '@shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RadioButtonModule } from 'primeng/radiobutton';

import { AgentQuestionnaireLeadDetailComponent } from './components/agent-questionnaire-lead-detail/agent-questionnaire-lead-detail.component';
import { QuestionInputTypeDmusComponent } from './components/question-input-types/dmus-input-type/dmus-input-type.component';
import { QuestionInputTypeDropdownSingleComponent } from './components/question-input-types/dropdown-single-input-type/dropdown-single-input-type.component';
import { QuestionInputTypeEmptyComponent } from './components/question-input-types/empty-input-type/empty-input-type.component';
import { QuestionInputTypeMultipleChoiceSingleComponent } from './components/question-input-types/multiple-choice-single-input-type/multiple-choice-single-input-type.component';
import { QuestionInputTypeOpenQuestionComponent } from './components/question-input-types/open-question-input-type/open-question-input-type.component';
import { QuestionInputTypeOpenQuestionLargeComponent } from './components/question-input-types/open-question-large-input-type/open-question-large-input-type.component';
import { questionInputTypeComponents } from './components/question-input-types/question-input-type-map';
import { QuestionInputTypesComponent } from './components/question-input-types/question-input-types/question-input-types.component';
import { QuestionInputTypeYesNoComponent } from './components/question-input-types/yes-no-input-type/yes-no-input-type.component';
import { QuestionnaireFormComponent } from './components/questionnaire-form/questionnaire-form.component';
import { QuestionnaireQuestionDialogComponent } from './components/questionnaire-question-dialog/questionnaire-question-dialog.component';
import { QuestionnaireStepsDialogComponent } from './components/questionnaire-steps-dialog/questionnaire-steps-dialog.component';
import { QuestionnaireDetailComponent } from './pages/questionnaire-detail/questionnaire-detail.component';
import { QuestionnaireListComponent } from './pages/questionnaire-list/questionnaire-list.component';
import { GetQuestionNumberPipe } from './pipes/get-question-number.pipe';
import { QUESTION_INPUT_TYPE_COMPONENTS } from './providers/questionnaire-question-type.provider';
import { QuestionnaireRoutingModule } from './questionnaire-routing.module';
import { UnansweredQuestionsWarningComponent } from '@features/questionnaire/components/unanswered-questions-warning/unanswered-questions-warning.component';
import { CallbackDialogComponent } from './components/callback-dialog/callback-dialog.component';
import { AddressInputTypeComponent } from './components/question-input-types/address-input-type/address-input-type.component';
import { ContactInputTypeComponent } from './components/question-input-types/contact-input-type/contact-input-type.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ReplaceQuestionTagsPipe } from '@features/questionnaire/pipes/replace-question-tags.pipe';
import { CallbackTimePipe } from './pipes/callback-time.pipe';

@NgModule({
  declarations: [
    QuestionnaireListComponent,
    QuestionnaireDetailComponent,
    QuestionnaireQuestionDialogComponent,
    QuestionnaireStepsDialogComponent,
    QuestionnaireFormComponent,
    QuestionInputTypeOpenQuestionComponent,
    QuestionInputTypesComponent,
    QuestionInputTypeMultipleChoiceSingleComponent,
    QuestionInputTypeDropdownSingleComponent,
    QuestionInputTypeYesNoComponent,
    QuestionInputTypeEmptyComponent,
    QuestionInputTypeOpenQuestionLargeComponent,
    GetQuestionNumberPipe,
    QuestionInputTypeDmusComponent,
    AgentQuestionnaireLeadDetailComponent,
    UnansweredQuestionsWarningComponent,
    AddressInputTypeComponent,
    CallbackDialogComponent,
    ContactInputTypeComponent,
    ReplaceQuestionTagsPipe,
    CallbackTimePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuestionnaireRoutingModule,
    CapturumListRendererModule,
    CapturumButtonModule,
    LayoutModule,
    TranslateModule,
    NgxPermissionsModule,
    CapturumCardModule,
    CapturumFormRendererModule,
    CapturumSharedModule,
    SharedModule,
    BaseDataModule,
    DragDropModule,
    CapturumProgressBarModule,
    CdkStepperModule,
    CapturumInputModule,
    CapturumRadioModule,
    RadioButtonModule,
    CapturumDropdownModule,
    CapturumCheckboxModule,
    CapturumMultiSelectModule,
    CapturumTextareaModule,
    CheckboxModule,
  ],
  exports: [
    QuestionnaireFormComponent,
    AgentQuestionnaireLeadDetailComponent,
    AddressInputTypeComponent,
    ContactInputTypeComponent,
  ],
  providers: [
    {
      provide: QUESTION_INPUT_TYPE_COMPONENTS,
      useValue: questionInputTypeComponents,
    },
  ],
})
export class QuestionnaireModule {}

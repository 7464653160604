import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AuthService } from '@capturum/auth';
import { CapturumBuildersContextService, FormBuilderConfig } from '@capturum/builders/core';
import {
  CapturumFormRendererComponent,
  FormManipulatorService,
  FormRendererApiService,
  FormRendererService,
  FormSaverService,
} from '@capturum/builders/form-renderer';
import { BaseDataIndexedDbModel, BaseDataKeyService } from '@capturum/complete';
import { DestroyBase } from '@capturum/shared';
import { MapItem, TableAction } from '@capturum/ui/api';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { MetaKeyApiService } from '@features/meta-key/services/meta-key-api.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationBaseService } from '@shared/services/confirmation-base.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs';
import { pairwise, skip } from 'rxjs/operators';

import { QuestionType } from '../../enums/question-type.enum';
import { Question, QuestionOption } from '../../interfaces/questionnaire.interface';

interface ActionCallBackItem {
  option: QuestionOption;
  index: number;
}

@Component({
  selector: 'app-questionnaire-question-dialog',
  templateUrl: './questionnaire-question-dialog.component.html',
  styleUrls: ['./questionnaire-question-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class QuestionnaireQuestionDialogComponent extends DestroyBase implements OnInit {
  @ViewChild('optionsForm')
  public optionsForm: CapturumFormRendererComponent;

  public questionFormKey = 'form_questionnaire-question';
  public questionOptionsFormKey = 'form_questionnaire-question-option';
  public questionOptionsShow$: Observable<boolean>;
  public questionTypes$: Observable<MapItem[]>;
  public formConfiguration: FormBuilderConfig;
  public optionsListSubject: BehaviorSubject<QuestionOption[]> = new BehaviorSubject<QuestionOption[]>([]);
  public optionsList$: Observable<QuestionOption[]> = this.optionsListSubject.asObservable();
  public rowActions: TableAction[];
  public hideOptionsForm: boolean;
  public optionDefaultValue: QuestionOption;
  public isNewOption = true;
  public questionnaireId: string;
  public questionId: string;
  public index: number;
  public questions: Question[] = [];
  public hideNewOptionsForm: boolean;

  private _questionTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _editedIndex: number;

  constructor(
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formSaver: FormSaverService,
    private formRendererService: FormRendererService,
    private baseDataKeyService: BaseDataKeyService,
    private authService: AuthService,
    private formRendererApiService: FormRendererApiService,
    private translateService: TranslateService,
    private confirmationBaseService: ConfirmationBaseService,
    private contextService: CapturumBuildersContextService,
    private metaKeyService: MetaKeyApiService,
    private formManipulatorService: FormManipulatorService,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.index = config?.data?.index;
    this.questionnaireId = config?.data?.questionnaireId;
    this.questionId = config?.data?.question?.id;
    this.questions = config?.data?.questions;
    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
  }

  public get optionsListValue(): QuestionOption[] {
    return this.optionsListSubject.getValue();
  }

  public ngOnInit(): void {
    this.rowActions = this.generateRowActions();
    this.getFormConfigurations();

    this.questionTypes$ = this.baseDataKeyService.getBaseDataKeyValues('question_type').pipe(
      map((types) => {
        const includeTypes = [
          QuestionType.MULTIPLE_CHOICE_SINGLE_ANSWER,
          QuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER,
          QuestionType.DROPDOWN_SINGLE_ANSWER,
          QuestionType.DROPDOWN_MULTIPLE_ANSWER,
          QuestionType.YES_NO,
        ];

        return types.filter((type) => {
          return includeTypes.includes(type.key as QuestionType);
        });
      }),
      shareReplay(1)
    );

    this.questionOptionsShow$ = combineLatest([this.questionTypes$, this._questionTypeSubject.asObservable()]).pipe(
      map(([baseDataValues, questionType]) => {
        return baseDataValues
          .map((value) => {
            return value.value;
          })
          .includes(questionType);
      }),
      shareReplay(1)
    );

    this.formRendererService
      .getFormValueStreamByKey(this.questionFormKey)
      .pipe(filter(Boolean), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((formValues) => {
        if (formValues.metaKey?.options?.length) {
          this.hideNewOptionsForm = true;
        }

        if (formValues?.question_type_base_data_value_id) {
          this._questionTypeSubject.next(formValues.question_type_base_data_value_id);
          this.optionDefaultValue = {
            ...this.optionDefaultValue,
            question_type_base_data_value_id: formValues.question_type_base_data_value_id,
          };

          if (formValues.questionOptions?.length) {
            this.hideOptionsForm = true;
            this.optionsListSubject.next(formValues.questionOptions);
          }
        }
      });

    let waitFor$: Observable<boolean | Question> = of(true);

    if (this.questionId) {
      waitFor$ = this.formRendererService.getSourceValueByKey(this.questionFormKey).pipe(first());
    }

    waitFor$
      .pipe(
        switchMap(() => {
          return this.formRendererService.getFormValueStreamByKey(this.questionFormKey).pipe(
            filter((formValue) => {
              return !!formValue?.meta_key_id;
            })
          );
        }),
        map((formValue) => {
          return formValue.meta_key_id;
        }),
        distinctUntilChanged(),
        skip(this.questionId ? 1 : 0),
        switchMap((metaKeyId) => {
          return this.metaKeyService.get(metaKeyId, { include: ['options'] });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((meta) => {
        let options: QuestionOption[] = [];

        if (meta.options?.length) {
          this.hideNewOptionsForm = true;
          this.hideOptionsForm = true;
          options = meta.options.map((option, index) => {
            return {
              ...this.getNewQuestionOption(option.name),
              order: index,
            };
          });
        } else {
          this.hideNewOptionsForm = false;
          this.hideOptionsForm = false;
        }

        this.optionsListSubject.next(options);
      });

    this._questionTypeSubject
      .asObservable()
      .pipe(
        pairwise(),
        filter(([previousType, currentType]) => {
          return previousType !== currentType;
        }),
        distinctUntilChanged(),
        switchMap(([previousType, currentType]) => {
          return combineLatest([
            of(previousType),
            of(currentType),
            this.formRendererService.getSourceValueByKey(this.questionFormKey).pipe(first()),
          ]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(async ([previousType, currentType, questionFormValue]) => {
        const questionTypeBaseData = await BaseDataIndexedDbModel.query().where({ key: 'question_type' }).first();
        const yesNoBaseDataValueId = questionTypeBaseData.values.find((value) => {
          return value.value === QuestionType.YES_NO;
        }).id;

        if (currentType === yesNoBaseDataValueId) {
          this.hideNewOptionsForm = true;

          if (!this.questionId || (this.questionId && previousType !== yesNoBaseDataValueId)) {
            this.hideOptionsForm = true;

            if (questionFormValue?.questionOptions?.length) {
              this.optionsListSubject.next(questionFormValue.questionOptions);
            } else {
              this.optionsListSubject.next([
                this.getNewQuestionOption(this.translateService.instant('general.yes')),
                this.getNewQuestionOption(this.translateService.instant('general.no')),
              ]);
            }
          }
        } else if (previousType === yesNoBaseDataValueId) {
          this.optionsListSubject.next([]);
          this.hideNewOptionsForm = false;
          this.hideOptionsForm = false;
        }
      });
  }

  public submit(): void {
    const additionalValue = {
      questionOptions: this.optionsListValue,
      order: this.index > -1 ? this.index : this.questions.length,
    };

    this.formSaver
      .submit(this.questionFormKey, additionalValue)
      .pipe(
        map(({ response }) => {
          return response.data;
        })
      )
      .subscribe((data) => {
        this.dialogRef.close({ action: DialogActionType.submit, data });
      });
  }

  public addOption(): void {
    this.isNewOption = true;

    this.formRendererService.getFormValueByKey(this.questionOptionsFormKey).subscribe((formValue) => {
      this.optionsListSubject.next([
        ...this.optionsListValue,
        {
          ...formValue,
          order: this.optionsListValue.length,
        },
      ]);

      this.hideOptionsForm = true;
    });
  }

  public editOption(): void {
    this.formSaver
      .submit(this.questionOptionsFormKey)
      .pipe(
        switchMap(() => {
          return this.formRendererService.getFormValueByKey(this.questionOptionsFormKey);
        })
      )
      .subscribe((formValue) => {
        this.optionsListValue[this._editedIndex] = formValue;
        this.optionsListSubject.next([
          ...this.optionsListValue.map((option, order) => {
            return { ...option, order };
          }),
        ]);

        this.hideOptionsForm = true;
      });
  }

  public addNewOption(): void {
    this.hideOptionsForm = false;

    this.cdr.detectChanges();

    if (this.optionsForm) {
      this.optionsForm.form.reset();
      this.optionsForm.defaultValue = {
        question_type_base_data_value_id: this.optionDefaultValue.question_type_base_data_value_id,
      };
    }

    this.isNewOption = true;
  }

  private getFormConfigurations(): void {
    this.contextService.setContext(this.questionOptionsFormKey, {
      questionnaire_id: this.questionnaireId,
      question_id: this.questionId,
    });

    this.formRendererApiService
      .getFormBuilderByKey(this.questionOptionsFormKey)
      .pipe(first())
      .subscribe((questionOptionsConfig) => {
        this.formConfiguration = questionOptionsConfig;
      });
  }

  private generateRowActions(): TableAction[] {
    return [
      {
        label: this.translateService.stream('button.edit'),
        callback: ({ option, index }: ActionCallBackItem) => {
          this.hideOptionsForm = true;

          this.formConfiguration = { ...this.formConfiguration };
          this.optionDefaultValue = {
            ...option,
            disable_value: this.hideNewOptionsForm,
            question_type_base_data_value_id: this._questionTypeSubject.getValue(),
          };

          this.cdr.detectChanges();

          this.hideOptionsForm = false;
          this.isNewOption = false;
          this._editedIndex = index;

          if (this.hideNewOptionsForm) {
            this.formManipulatorService.setFieldManipulation(this.questionOptionsFormKey, 'value', { read_only: true });
          }
        },
        icon: 'fas fa-pencil-alt',
        value: null,
      },
      {
        label: this.translateService.stream('button.delete'),
        callback: (event: ActionCallBackItem) => {
          this.confirmationBaseService.confirmationService.confirm({
            header: this.translateService.instant(`market_discovery.questionnaire.question.delete.label`),
            message: this.translateService.instant(`market_discovery.questionnaire.question.delete_confirmation.label`),
            accept: () => {
              return this.deleteOption(event.index);
            },
          });
        },
        icon: 'fas fa-trash',
        value: null,
      },
    ];
  }

  private deleteOption(index: number): void {
    this.optionsListValue.splice(index, 1);
    this.optionsListSubject.next(this.optionsListValue);

    if (this.optionsListValue.length === 0) {
      this.addNewOption();
    }
  }

  private getNewQuestionOption(value: string): QuestionOption {
    return {
      value,
      description: null,
      is_refer: false,
      is_free_text: false,
    };
  }
}

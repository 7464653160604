import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, filter, first, map, Observable, shareReplay, switchMap, takeUntil } from 'rxjs';
import { Question, Questionnaire } from '@features/questionnaire/interfaces/questionnaire.interface';
import { CapturumDialogService, TableAction, ToastService } from '@capturum/ui/api';
import { ListOptions } from '@capturum/api';
import { FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationBaseService } from '@shared/services/confirmation-base.service';
import { QuestionnaireApiService } from '@features/questionnaire/services/questionnaire-api.service';
import { QuestionnaireQuestionDialogComponent } from '@features/questionnaire/components/questionnaire-question-dialog/questionnaire-question-dialog.component';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { AppRoutes } from '@core/enums/routes.enum';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { QuestionService } from '@features/questionnaire/services/question.service';
import { tap } from 'rxjs/operators';
import { DestroyBase } from '@capturum/shared';
import { MetaKeyApiService } from '@features/meta-key/services/meta-key-api.service';

interface ActionCallBackItem {
  question: Question;
  index: number;
}

@Component({
  selector: 'app-questionnaire-form',
  templateUrl: './questionnaire-form.component.html',
  styleUrls: ['./questionnaire-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireFormComponent extends DestroyBase implements OnInit {
  @Input()
  set questionnaireId(value: string) {
    this._questionnaireId.next(value);
  }

  @Input()
  public contextKey: string;

  @Input()
  public isDmu: boolean;

  @Input()
  public disableGlobalQuestionnaire: boolean;

  @Output()
  public questionChanged = new EventEmitter<Question[]>();

  @Output()
  public questionnaireDisabled = new EventEmitter<boolean>();

  @Output()
  public questionsOrderChanged = new EventEmitter<Question[]>();

  public disabled: boolean;
  public questionFormKey = 'form_questionnaire-question';
  public questionsSubject = new BehaviorSubject<Question[]>([]);
  public questionnaireQuestions$: Observable<Question[]> = this.questionsSubject.asObservable();
  public rowActions: TableAction[];
  public questionnaire$: Observable<Questionnaire>;
  public apiOptions: ListOptions = {
    include: ['questions.questionOptions'],
  };

  public context: Record<string, any>;
  public formKey = 'form_questionnaire';

  private _questionnaireId = new BehaviorSubject<string>(undefined);

  constructor(
    protected formSaverService: FormSaverService,
    protected route: ActivatedRoute,
    private router: Router,
    protected toastService: ToastService,
    protected translateService: TranslateService,
    private dialogService: CapturumDialogService,
    private formRendererService: FormRendererService,
    private confirmationBaseService: ConfirmationBaseService,
    private questionnaireApiService: QuestionnaireApiService,
    private questionService: QuestionService,
    private metaKeyService: MetaKeyApiService
  ) {
    super();
  }

  public get questions(): Question[] {
    return this.questionsSubject.getValue();
  }

  public ngOnInit(): void {
    this.context = {
      isEdit: true,
      questionnaire_id: this._questionnaireId.getValue(),
      isDmu: this.isDmu,
    };

    this.rowActions = this.generateRowActions();

    this.questionnaire$ = this._questionnaireId.asObservable().pipe(
      filter(Boolean),
      switchMap((id) => {
        return this.questionnaireApiService.get(id, this.apiOptions);
      }),
      tap((questionnaire) => {
        this.disabled = this.disableGlobalQuestionnaire && !questionnaire.project_id;
        this.questionnaireDisabled.emit(this.disabled);
      }),
      shareReplay(1)
    );

    this.questionnaire$
      .pipe(
        map((questionnaire) => {
          return questionnaire.questions;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((questions) => {
        this.questionsSubject.next(questions);
        this.questionChanged.emit(this.questions);
      });
  }

  public openQuestionDialog(index?: number): void {
    const question = this.questions[index];

    this.dialogService
      .open(QuestionnaireQuestionDialogComponent, {
        header: this.translateService.instant(
          `market_discovery.questionnaire.${index > -1 ? 'question_edit' : 'question_new'}.label`
        ),
        styleClass: 'questionnaire-question-dialog',
        data: {
          questions: this.questions,
          question,
          index,
          questionnaireId: this._questionnaireId.getValue(),
        },
      })
      .onClose.pipe(
        first(),
        filter((value) => {
          return value?.action === DialogActionType.submit;
        }),
        switchMap(({ data }) => {
          return this.formRendererService.getFormValueByKey(this.questionFormKey).pipe(
            map((formValue) => {
              return { ...formValue, ...data };
            })
          );
        })
      )
      .subscribe((formQuestion: Question) => {
        if (index > -1) {
          this.questions[index] = formQuestion;
        } else {
          this.questions.push(formQuestion);
        }

        this.questionsSubject.next(this.questions);
        this.questionChanged.emit(this.questions);
      });
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey, { questions: this.questions, id: this._questionnaireId.getValue() })
      .pipe(first())
      .subscribe(({ response }) => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant(
            `market_discovery.entity.toast.${this._questionnaireId.getValue() ? 'updated' : 'created'}`,
            { entity: 'questionnaire' }
          )
        );

        this.router.navigate([
          this._questionnaireId.getValue() ? AppRoutes.questionnaire : `${AppRoutes.questionnaire}/${response.data.id}`,
        ]);
      });
  }

  public cancel(): void {
    this.router.navigate([AppRoutes.questionnaire]);
  }

  public handleQuestionDrop(event: CdkDragDrop<Question[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
      this.questionsSubject.next(
        this.questions.map((question, index) => {
          return {
            ...question,
            order: index,
          };
        })
      );

      this.questionsOrderChanged.emit(this.questions);
    }
  }

  private generateRowActions(): TableAction[] {
    return [
      {
        label: this.translateService.stream('button.edit'),
        callback: ({ index }: ActionCallBackItem) => {
          this.openQuestionDialog(index);
        },
        icon: 'fas fa-pencil-alt',
        value: null,
      },
      {
        label: this.translateService.stream('button.delete'),
        callback: (event: ActionCallBackItem) => {
          this.confirmationBaseService.confirmationService.confirm({
            header: this.translateService.instant(`market_discovery.questionnaire.question.delete.label`),
            message: this.translateService.instant(`market_discovery.questionnaire.question.delete_confirmation.label`),
            accept: () => {
              return this.deleteQuestion(event.index);
            },
          });
        },
        icon: 'fas fa-trash-can',
        value: null,
      },
    ];
  }

  private deleteQuestion(index: number): void {
    this.questionService.delete(this.questions[index].id).subscribe(() => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('market_discovery.entity.toast.deleted', {
          entity: this.translateService.instant('market_discovery.questionnaire.question.question.label'),
        })
      );

      const questions = this.questions
        .filter((question, questionIndex) => {
          return questionIndex !== index;
        })
        .map((question, order) => {
          return { ...question, order };
        });

      this.questionsSubject.next(questions);
      this.questionChanged.emit(questions);
    });
  }
}

import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@shared/services/storage.service';
import { ProjectSelectorDialogComponent } from './../../features/project/components/project-selector-dialog/project-selector-dialog.component';
import { CapturumDialogService } from '@capturum/ui/api';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { filter, Observable, Subscriber, switchMap } from 'rxjs';
import { Project } from 'src/app/features/project/interfaces/project.interface';
import { CompleteResolver } from '@capturum/complete';
import { ProjectApiService } from '../../features/project/services/project-api.service';
import { toMapItems } from '@capturum/builders/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProjectStateService } from '../../features/project/services/project-state.service';
import { first, shareReplay } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class ProjectSelectorResolver {
  public completeResolver$: Observable<boolean>;

  constructor(
    private dialogService: CapturumDialogService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private completeResolver: CompleteResolver,
    private projectApiService: ProjectApiService,
    private permissionService: NgxPermissionsService,
    private projectStateService: ProjectStateService,
    private router: Router
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const activeProject = this.localStorageService.get<Project>('active-project');

    this.completeResolver$ = this.completeResolver.resolve(route, state).pipe(shareReplay(1));

    return new Observable((observer: Subscriber<boolean>) => {
      if (!this.projectStateService.getProjectSnapshot()?.id) {
        this.completeResolver$
          .pipe(
            filter(Boolean),
            first(),
            switchMap(() => {
              return this.projectApiService.list().pipe(toMapItems);
            })
          )
          .subscribe((projectList) => {
            if (!this.permissionService.getPermission('project.create')) {
              if (!activeProject) {
                if (projectList.length > 1) {
                  this.dialogService.open(ProjectSelectorDialogComponent, {
                    header: this.translateService.instant('market_discovery.project.dialog_selector.title'),
                    width: '60%',
                    styleClass: 'project-selector-dialog',
                    data: {
                      projectList,
                    },
                  });
                } else if (projectList.length === 1) {
                  this.projectApiService
                    .get(projectList[0].value)
                    .pipe(first())
                    .subscribe((project) => {
                      if (!this.projectStateService.getProjectSnapshot()) {
                        this.projectStateService.setProject(project);
                        this.router.navigate([`${AppRoutes.project}/${project.id}/${AppRoutes.xyz}`]);
                      }
                    });
                }
              } else if (activeProject && !this.projectStateService.getProjectSnapshot()?.id) {
                this.projectStateService.setProject(activeProject);
                this.router.navigate([`${AppRoutes.project}/${activeProject.id}/${AppRoutes.xyz}`]);
              }
            }

            observer.next(true);
            observer.complete();
          });
      } else {
        this.completeResolver$.pipe(first()).subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      }
    });
  }
}

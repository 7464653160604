<div class="cap-dynamic-input-filter">
  <div class="cap-dynamic-filter-layout">
    <div class="cap-dynamic-filter-layout-content">
      <cap-input
        [formControl]="formControl"
        [attr.data-test]="'filter-' + filterConfig.field"
        [disabled]="filterConfig.disabled | observablePipe | async"
        [placeholder]="filterConfig.placeholder"></cap-input>

      <div class="filter-icon">
        <i [class]="filterConfig.icon"></i>
      </div>

      <div class="delete-filter" *ngIf="formControl.value && (filterConfig.resetFilter ?? true)" (click)="reset()">
        <i class="fas fa-trash-alt"></i>
      </div>
    </div>
  </div>
</div>

import { Questionnaire } from './../../interfaces/questionnaire.interface';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ListOptions } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { BaseDataKeyService } from '@capturum/complete';
import { MapItem, ToastService } from '@capturum/ui/api';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { Lead, LeadCallback } from '@features/lead/interfaces/lead.interface';
import { Question, QuestionAnswer, UpdateAnswer } from '@features/questionnaire/interfaces/questionnaire.interface';
import { QuestionnaireApiService } from '@features/questionnaire/services/questionnaire-api.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationBaseService } from '@shared/services/confirmation-base.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';

import { AgentQuestionnaireLeadDetailComponent } from '../agent-questionnaire-lead-detail/agent-questionnaire-lead-detail.component';
import { QuestionType } from './../../enums/question-type.enum';
import { QuestionInputTypesComponent } from './../question-input-types/question-input-types/question-input-types.component';
import { CallbackDialogComponent } from '@features/questionnaire/components/callback-dialog/callback-dialog.component';
import { DOCUMENT } from '@angular/common';
import { Address } from '@core/interfaces/address.interface';
import { Contact } from '@core/interfaces/contact.interface';
import { LeadStatus } from '@features/lead/lead-status.enum';
import isAfter from 'date-fns/isAfter';
import { Company } from '@features/project/interfaces/company.interface';
import { ConfirmEventType } from 'primeng/api';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { AgentQuestionnaireStateService } from '@features/questionnaire/services/agent-questionnaire-state.service';

enum StepDirection {
  NEXT = 'next',
  PREVIOUS = 'previous',
}

enum ShortKeys {
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
}

@Component({
  selector: 'app-questionnaire-steps-dialog',
  templateUrl: './questionnaire-steps-dialog.component.html',
  styleUrls: ['./questionnaire-steps-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionnaireStepsDialogComponent implements OnInit, OnDestroy {
  public progressValue = 0;
  public questionnaireId: string;
  public questions$: Observable<Question[]>;
  public apiOptions: ListOptions = {
    include: ['questions.questionOptions', 'questions.type'],
  };

  public isEdit: boolean;
  public questionTypes$: Observable<MapItem[]>;
  public questionType = QuestionType;
  public selectedIndex = 0;
  public answers: Map<string, QuestionAnswer> = new Map();
  public lead: Lead;
  public showSummary: boolean;
  public unansweredQuestions: Question[] = [];
  public showWarningScreen: boolean;
  public answeredQuestions: QuestionAnswer[];
  public callCenterStatuses$: Observable<MapItem[]>;
  public requiredCallbackStatusIds: string[] = [];
  public showCallbackButton$: Observable<boolean>;
  public submitting$ = new Subject<boolean>();
  public _originalLead: Lead;
  private _questions: Question[] = [];
  private _previousIsReferIndex: number;
  private _currentQuestion: Question;
  private _questionnaires: {
    dmu_questionnaire: Questionnaire;
    questionnaire: Questionnaire;
    lead_callbacks: LeadCallback[];
  };

  private currentQuestionnaireId: string;
  private questionnaireStepsHistory: { from: number; to: number; direction: StepDirection }[] = [];
  private questionnaireSteps: Array<number> = [];
  private shortKeysUnListener: () => void;
  private leadDetails$: BehaviorSubject<AgentQuestionnaireLeadDetailComponent> =
    new BehaviorSubject<AgentQuestionnaireLeadDetailComponent>(null);

  constructor(
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private questionnaireApiService: QuestionnaireApiService,
    private baseDataKeyService: BaseDataKeyService,
    private authService: AuthService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private confirmationBaseService: ConfirmationBaseService,
    private dialogService: DialogService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private leadService: LeadApiService,
    private agentQuestionnaireState: AgentQuestionnaireStateService
  ) {}

  private _leadDetails: AgentQuestionnaireLeadDetailComponent;

  @ViewChild('leadDetails')
  public set leadDetails(value: AgentQuestionnaireLeadDetailComponent) {
    this._leadDetails = value;

    if (value) {
      this.leadDetails$.next(value);
    }
  }

  private _questionInputTypes: QuestionInputTypesComponent;

  @ViewChild('questionInputTypes')
  public set questionInputTypes(value: QuestionInputTypesComponent) {
    if (value) {
      this._questionInputTypes = value;
    }
  }

  public ngOnInit(): void {
    this.questionnaireId = this.config?.data?.questionnaireId;
    this.lead = this.config?.data?.lead;
    this._originalLead = this.lead;
    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
    this.answeredQuestions = this.config?.data?.answers;
    this._questionnaires = this.config.data.questionnaires;

    this.shortKeysUnListener = this.renderer.listen(this.document, 'keydown', this.listenToKeyboardEvents.bind(this));

    this.questions$ = combineLatest([
      this.baseDataKeyService.getBaseDataKeyValues('question_type'),
      of(this._questionnaires.dmu_questionnaire ?? this._questionnaires.questionnaire),
    ]).pipe(
      map(([types, questionnaire]) => {
        this.currentQuestionnaireId = questionnaire.id;

        const questions = [
          ...(this._questionnaires?.dmu_questionnaire?.questions || []),
          ...(this._questionnaires?.questionnaire?.questions || []),
        ];

        this.questionnaireSteps =
          JSON.parse(this._questionnaires?.dmu_questionnaire?.questionnaire_users?.[0]?.steps_history || null) ??
          JSON.parse(this._questionnaires?.questionnaire?.questionnaire_users?.[0]?.steps_history || null) ??
          [];

        return questions.map((question) => {
          return {
            ...question,
            question_type: types.find((type) => {
              return type.value === question.question_type_base_data_value_id;
            })?.key as QuestionType,
          };
        });
      }),
      tap((questions) => {
        this._questions = questions;

        if (this.answeredQuestions?.length) {
          this.answeredQuestions.forEach((answer) => {
            let { ...questionAnswer } = this.answeredQuestions.find((lookupAnswer) => {
              return lookupAnswer.question_id === answer.question_id;
            });

            if (questionAnswer?.hasOwnProperty('answerOptions')) {
              questionAnswer = {
                ...questionAnswer,
                option_ids: questionAnswer.answerOptions.map((answerOption) => {
                  return {
                    option_id: answerOption.question_option_id,
                    answer_text: answerOption.answer_text,
                  };
                }),
              };

              delete questionAnswer['answerOptions'];
            }

            if (questionAnswer?.hasOwnProperty('question_option_id')) {
              questionAnswer = {
                ...questionAnswer,
                option_id: questionAnswer.question_option_id,
              };

              delete questionAnswer['question_option_id'];
            }

            this.answers.set(answer.question_id, this.isEmptyObject(questionAnswer) ? null : questionAnswer);
          });

          const lastAnswerQuestionId = this.answeredQuestions[this.answeredQuestions.length - 1]?.question_id;
          const lastAnsweredQuestionIndex = questions.findIndex((question) => {
            return question.id === lastAnswerQuestionId;
          });

          this.selectedIndex =
            lastAnsweredQuestionIndex === questions.length - 1
              ? lastAnsweredQuestionIndex
              : lastAnsweredQuestionIndex + 1;

          const isReferQuestionTypes = [
            QuestionType.MULTIPLE_CHOICE_SINGLE_ANSWER,
            QuestionType.DROPDOWN_SINGLE_ANSWER,
            QuestionType.YES_NO,
          ];

          if (lastAnsweredQuestionIndex === questions.length - 1) {
            this.selectedIndex = lastAnsweredQuestionIndex;
          } else if (isReferQuestionTypes.includes(this._questions[lastAnsweredQuestionIndex].question_type)) {
            const nextQuestionIndex = this.getNextQuestionIsReferIndex(this._questions[lastAnsweredQuestionIndex]);

            if (nextQuestionIndex > -1) {
              this.selectedIndex = nextQuestionIndex;
            }
          } else {
            this.selectedIndex = lastAnsweredQuestionIndex + 1;
          }

          if (this.questionnaireSteps.length) {
            this.questionnaireStepsHistory = this.questionnaireSteps.reduce((acc, step, index) => {
              if (index < this.questionnaireSteps.length - 1) {
                acc.push({
                  from: step,
                  to: this.questionnaireSteps[index + 1],
                  direction: StepDirection.NEXT,
                });
              }

              return acc;
            }, []);

            this.selectedIndex = this.questionnaireSteps[this.questionnaireSteps.length - 1];
          }
        }

        this.progressValue = (this.selectedIndex + 1 / this._questions.length) * 100;
        this._currentQuestion = this._questions[this.selectedIndex];
      })
    );

    this.callCenterStatuses$ = this.questionnaireApiService.getCallCenterStatuses().pipe(
      tap((statuses) => {
        const callbackRequiredStatuses = [LeadStatus.a5, LeadStatus.b1, LeadStatus.b2];

        this.requiredCallbackStatusIds = statuses.reduce((statusIds: string[], status) => {
          if (callbackRequiredStatuses.includes(status.key as LeadStatus)) {
            return [...statusIds, status.value];
          }

          return statusIds;
        }, []);
      }),
      shareReplay(1)
    );

    this.showCallbackButton$ = this.callCenterStatuses$.pipe(
      switchMap((statuses) => {
        return this.leadDetails$.asObservable().pipe(
          filter(Boolean),
          map((leadDetails) => {
            return {
              statuses,
              leadDetails,
            };
          })
        );
      }),
      switchMap(({ statuses, leadDetails }) => {
        return leadDetails.form.get('lead_status_base_data_value_id').valueChanges.pipe(
          startWith(leadDetails.form.value.lead_status_base_data_value_id),
          map((status) => {
            return {
              status,
              statuses,
            };
          })
        );
      }),
      map(({ status, statuses }) => {
        const a5StatusId: string = statuses.find((callCenterStatus) => {
          return callCenterStatus.key === LeadStatus.a5;
        })?.value as string;

        return [a5StatusId, ...this.requiredCallbackStatusIds].includes(status);
      })
    );
  }

  public ngOnDestroy(): void {
    this.shortKeysUnListener();
    this.agentQuestionnaireState.setLeadDetails(null);
  }

  public next(): void {
    let nextSelectedIndex = this.selectedIndex + 1;

    if (this._questionInputTypes.isValid()) {
      if (
        this._currentQuestion.question_type === QuestionType.MULTIPLE_CHOICE_SINGLE_ANSWER ||
        this._currentQuestion.question_type === QuestionType.DROPDOWN_SINGLE_ANSWER ||
        this._currentQuestion.question_type === QuestionType.YES_NO
      ) {
        const nextQuestionIndex = this.getNextQuestionIsReferIndex(this._currentQuestion);

        if (nextQuestionIndex > -1) {
          nextSelectedIndex = nextQuestionIndex;
        }
      } else {
        const nextQuestionIndex = this._currentQuestion.go_to_question_id
          ? this._questions.findIndex((currentQuestion) => {
              return currentQuestion.id === this._currentQuestion.go_to_question_id;
            })
          : -1;

        if (nextQuestionIndex > -1) {
          nextSelectedIndex = nextQuestionIndex;
        }
      }

      const fromQuestionIndex = this.selectedIndex;

      this.selectedIndex = Math.min(nextSelectedIndex, this._questions.length - 1);
      this._currentQuestion = this._questions[this.selectedIndex];

      this.questionnaireStepsHistory.push({
        from: fromQuestionIndex,
        to: this.selectedIndex,
        direction: StepDirection.NEXT,
      });
      this.parseQuestionnaireSteps();
    } else {
      this.showErrorToast();
    }
  }

  public previous(): void {
    if (this.showWarningScreen) {
      this.showWarningScreen = false;

      return;
    }

    if (this.questionnaireSteps.length) {
      this._previousIsReferIndex = this.questionnaireSteps[this.questionnaireSteps.length - 2];

      this.answers.set(this._currentQuestion.id, this._questionInputTypes.getAnswer());

      this.questionnaireStepsHistory.push({
        from: this.selectedIndex,
        to: this._previousIsReferIndex,
        direction: StepDirection.PREVIOUS,
      });

      this.parseQuestionnaireSteps();
    } else {
      this._previousIsReferIndex = this.getPreviousReferQuestionIndex();
    }

    this.selectedIndex = Math.max(
      this._previousIsReferIndex > -1
        ? this._previousIsReferIndex
        : this.selectedIndex - (this.showWarningScreen ? 0 : 1),
      0
    );
    this._currentQuestion = this._questions[this.selectedIndex];
  }

  public stop(): void {
    this.confirmationBaseService.confirmationService.confirm({
      header: this.translateService.instant('market_discovery.questionnaire.stop.confirmation.header'),
      message: this.translateService.instant('market_discovery.questionnaire.stop.confirmation.text'),
      acceptLabel: 'market_discovery.questionnaire.stop.confirmation.accept.label',
      rejectLabel: 'market_discovery.questionnaire.stop.confirmation.reject.label',
      reject: (confirmEventType: ConfirmEventType) => {
        if (confirmEventType !== ConfirmEventType.CANCEL) {
          this.showSummary = true;
          this.lead = this.getUpdatedLead();
        }
      },
    });
  }

  public finish(): void {
    if (this._questionInputTypes.isValid()) {
      if (!this.showWarningScreen) {
        this.unansweredQuestions = this._questions
          .map((question, index) => ({...question, order: index}))
          .filter((question) => {
            const answer = this.answers.get(question.id);
            
            return !answer || Object.values(answer).every((value) => {
              return value === null || value === false || value.length === 0;
            })
          })

        if (this.unansweredQuestions.length) {
          this.showWarningScreen = true;
        } else {
          this.showSummary = true;

          this.lead = this.getUpdatedLead();
        }
      } else {
        this.showSummary = true;

        this.lead = this.getUpdatedLead();
      }
    } else {
      this.showErrorToast();
    }
  }

  public updateAnswer(answer: UpdateAnswer): void {
    if (answer) {
      this.answers.set(answer.id, answer.value);
    }
  }

  public trackByQuestion(index: number, question: Question): string {
    return question.id;
  }

  public saveAndNext(): void {
    this.storeQuestionnaireAnswer();
  }

  public saveAndStop(): void {
    this.storeQuestionnaireAnswer(true);
  }

  public addCallBackAppointment(): void {
    const dialog = this.dialogService.open(CallbackDialogComponent, {
      header: this.translateService.instant('market_discovery.lead-callback.dialog.title'),
      styleClass: 'overflow-visible',
      data: {
        leadId: this.lead.id,
      },
    });

    dialog.onClose.subscribe((res) => {
      this.document.body.classList.add('dialog-open');
      if (res) {
        this.lead = { ...this.lead, leadCallbacks: res };
        this._originalLead = { ...this._originalLead, leadCallbacks: res };
      }
    });
  }

  public navigateToQuestion(question: Question | string): void {
    this.isEdit = true;
    this.showWarningScreen = false;
    this.showSummary = false;

    this.selectedIndex = this._questions.findIndex((questionnaireQuestion) => {
      return questionnaireQuestion.id === (typeof question === 'string' ? question : question.id);
    });

    this._currentQuestion = this._questions[this.selectedIndex];
    // find the index of the question in the questionnaireStepsHistory
    const questionStepsIndex = this.questionnaireStepsHistory.findIndex((step) => {
      return step.to === this.selectedIndex;
    });

    // remove all the steps after the selected question since we are going back to this question
    this.questionnaireStepsHistory = this.questionnaireStepsHistory.slice(0, questionStepsIndex + 1);

    this.parseQuestionnaireSteps();
  }

  private storeQuestionnaireAnswer(navigateToDashboard?: boolean): void {
    if (this._leadDetails.form.valid) {
      this.submitting$.next(true);
      if (this.validateCallbacks()) {
        const questionnaireAnswers = this._questions
          .map((question) => {
            const answer = this.answers.get(question.id);

            return {
              question_id: question.id,
              ...answer,
            };
          })
          .filter((answer) => {
            return Object.keys(answer).length > 1;
          });

        this.questionnaireApiService
          .storeAgentQuestionnaire(this.questionnaireId, {
            questionnaire_id: this._questionnaires?.questionnaire?.id ?? null,
            dmu_questionnaire_id: this._questionnaires?.dmu_questionnaire?.id ?? null,
            answers: questionnaireAnswers,
            lead_id: this.lead.id,
            remarks: this._leadDetails.form.value?.remarks,
            lead_status_base_data_value_id: this._leadDetails.form.value?.lead_status_base_data_value_id,
            steps_history: JSON.stringify(this.questionnaireSteps),
          })
          .subscribe({
            next: (data) => {
              this.dialogRef.close({ action: DialogActionType.submit, navigateToDashboard });
              this.submitting$.next(false);
            },
            error: () => {
              this.submitting$.next(false);
            },
          });
      } else {
        this.submitting$.next(false);
      }
    } else {
      this.showErrorToast();
      this.submitting$.next(false);
    }
  }

  private showErrorToast(): void {
    this.toastService.error(
      this.translateService.instant('builders.edit_input.validations.required'),
      this.translateService.instant('market_discovery.validation.questionnaire.question.required')
    );
  }

  private getNewCompany(): Company {
    const companyQuestion = this._questions.find((question) => {
      return question.question_type === QuestionType.COMPANY_NAME;
    });

    if (companyQuestion) {
      const answer = this.answers.get(companyQuestion.id);

      if (answer?.answer_text) {
        return {
          ...this._originalLead.company,
          name: answer.answer_text,
          isUpdated: this._originalLead.company.name !== answer.answer_text,
          questionId: companyQuestion.id,
        };
      }
    }

    return this._originalLead.company;
  }

  private getNewAddresses(addressType: QuestionType.VISITING_ADDRESS | QuestionType.MAILING_ADDRESS): Address {
    const allAddresses = this._originalLead.addresses;
    const currentMainAddress =
      addressType === QuestionType.VISITING_ADDRESS
        ? this._originalLead.mainCompanyAddress
        : this._originalLead.mainMailAddress;
    const addressQuestions = this._questions
      .filter((question) => {
        return question.question_type === addressType;
      })
      .map((question) => {
        return question?.id;
      });

    if (!addressQuestions.length) {
      return currentMainAddress;
    }

    let address: Address;

    this.answers.forEach((answer, questionId) => {
      if (addressQuestions.includes(questionId)) {
        if (answer?.address) {
          address = {
            ...answer.address,
            questionId,
          };
        } else if (answer.address_id) {
          address = {
            ...allAddresses.find((leadAddress) => {
              return leadAddress.id === answer.address_id;
            }),
            questionId,
          };
        }
      }
    });

    if (!address) {
      address = currentMainAddress;
    }

    return {
      ...address,
      isUpdated: !currentMainAddress || address?.id !== currentMainAddress?.id,
    };
  }

  private getNewDmus(): Contact[] {
    const answersArray = Array.from(this.answers).reduce((acc, answer) => {
      return [...acc, { question_id: answer[0], ...answer[1] }];
    }, []);

    return this.leadService.getNewDmus(this._originalLead, this._questions, answersArray);
  }

  private getNewContact(): Contact[] {
    const answersArray = Array.from(this.answers).reduce((acc, answer) => {
      return [...acc, { question_id: answer[0], ...answer[1] }];
    }, []);

    return this.leadService.getNewContact(this._originalLead, this._questions, answersArray);
  }

  private isEmptyObject(objectName: object): boolean {
    return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
  }

  private getPreviousReferQuestionIndex(): number {
    // retrieve all question options which have a is_refer
    const isReferQuestions = this._questions.reduce((acc, currentQuestion) => {
      if (
        currentQuestion.question_options &&
        currentQuestion.question_options.find((option) => {
          return option.is_refer === true;
        })
      ) {
        acc.push(...currentQuestion.question_options);
      }

      return acc;
    }, []);

    if (isReferQuestions?.length) {
      let selectedAnswerIsRefer: boolean;
      // find the questionOption which has a go_to_question_id for the current question id
      const previousIsReferQuestionId = isReferQuestions.find((question) => {
        return question.go_to_question_id === this._currentQuestion.id;
      })?.question_id;

      // Find the index of the question which has this go_to_question_id equal to the current question.
      const currentIndex = this._questions.findIndex((question) => {
        return question.id === previousIsReferQuestionId;
      });

      if (currentIndex > -1) {
        const currentQuestion = this._questions[currentIndex];
        // Find the answer of the question with this go_to_question_id option
        const previousReferAnswer = this.answers.get(currentQuestion?.id);

        // Check if the selected answer is a refer option and has the go_to_question_id set to the current question
        selectedAnswerIsRefer = currentQuestion.question_options.find((option) => {
          return option.id === previousReferAnswer?.option_id && option.go_to_question_id === this._currentQuestion.id;
        })?.is_refer;
      }

      return selectedAnswerIsRefer ? currentIndex : -1;
    }

    return -1;
  }

  private getNextQuestionIsReferIndex(question: Question): number {
    const currentQuestionAnswer = this.answers.get(question.id);
    const currentQuestionSelectedOption = question.question_options.find((option) => {
      return option.id === currentQuestionAnswer?.option_id;
    });

    if (currentQuestionSelectedOption?.is_refer) {
      return this._questions.findIndex((currentQuestion) => {
        return currentQuestion.id === currentQuestionSelectedOption.go_to_question_id;
      });
    }

    return -1;
  }

  private parseQuestionnaireSteps(): void {
    this.questionnaireSteps = this.questionnaireStepsHistory.reduce((acc, step, index) => {
      if (index === 0) {
        acc.push(step.from, step.to);
      } else {
        if (step.direction === StepDirection.NEXT) {
          acc.push(step.to);
        } else {
          acc.pop();
        }
      }

      return acc;
    }, []);
  }

  private validateCallbacks(): boolean {
    const today = new Date();

    const callbackAfterToday =
      this.lead.leadCallbacks &&
      this.lead.leadCallbacks.some((callback) => {
        const [hour, minutes]: string[] = callback.to?.split(':') || [null, null];
        const callbackToDate = new Date(callback.date);

        if (hour && minutes) {
          callbackToDate.setUTCHours(+hour);
          callbackToDate.setUTCMinutes(+minutes);
        } else {
          callbackToDate.setHours(23);
          callbackToDate.setMinutes(59);
        }

        return isAfter(callbackToDate, today);
      });

    let lastCallback = this.lead.leadCallbacks?.slice().pop();

    if (lastCallback && this.lead.leadCallbacks?.length === this._questionnaires.lead_callbacks?.length) {
      lastCallback = this._questionnaires.lead_callbacks?.slice().pop();
    }

    const callbackResolved = this.lead.leadCallbacks?.length > 0 ? lastCallback.is_resolved : true;

    if (
      this.requiredCallbackStatusIds.includes(this._leadDetails.form.value?.lead_status_base_data_value_id) &&
      (!callbackAfterToday || callbackResolved)
    ) {
      this.toastService.error(
        this.translateService.instant('toast.error.title'),
        this.translateService.instant('market_discovery.agent-questionnaire.callback.required-message')
      );

      return false;
    }

    return true;
  }

  private listenToKeyboardEvents(event: KeyboardEvent): void {
    if (event.ctrlKey || (event.ctrlKey && event.shiftKey)) {
      if (event.key === ShortKeys.LEFT) {
        if (this.selectedIndex > 0 && this.selectedIndex < this._questions.length) {
          this.previous();
        }
      } else if (event.key === ShortKeys.RIGHT) {
        if (this.selectedIndex >= 0 && this.selectedIndex < this._questions.length - 1) {
          this.next();
        }
      }
    }
  }

  private getUpdatedContacts(): Contact[] {
    return [...this.getNewContact(), ...this.getNewDmus()].filter((item, index, contacts) => {
      if (!item.id) {
        return true;
      }

      return (
        index ===
        contacts.findIndex((contact) => {
          return contact.id === item.id;
        })
      );
    });
  }

  private getUpdatedLead(): Lead {
    const leadDetails = this.agentQuestionnaireState.getLeadDetailsSnapshot();

    return {
      ...this._originalLead,
      company: {
        ...this._originalLead.company,
        ...this.getNewCompany(),
      },
      mainMailAddress: this.getNewAddresses(QuestionType.MAILING_ADDRESS),
      mainCompanyAddress: this.getNewAddresses(QuestionType.VISITING_ADDRESS),
      contacts: this.getUpdatedContacts(),
      ...(this.isEdit && (leadDetails?.lead_status_base_data_value_id || leadDetails?.remarks)
        ? {
            lead_status_base_data_value_id:
              leadDetails?.lead_status_base_data_value_id || this._originalLead.lead_status_base_data_value_id,
            md_remarks: leadDetails?.remarks || this._originalLead.md_remarks,
          }
        : null),
    };
  }
}

import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countSpacer',
})
export class CountSpacerPipe implements PipeTransform {
  public constructor(private decimalPipe: DecimalPipe) {}

  public transform(value: number, maxNumber: number): string {
    const maxFormatted = this.decimalPipe.transform(maxNumber);
    const valueFormatted = this.decimalPipe.transform(value);

    return maxFormatted.slice(0, -valueFormatted?.length).replace(/[0-9]/g, '0');
  }
}

import { PrintService } from '@shared/services/print.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { filter, first, map, shareReplay, tap } from 'rxjs/operators';
import { TableService } from '@shared/services/table.service';
import {
  ActiveFilters,
  CapturumDynamicFiltersComponent,
  DynamicFilterConfig,
  DynamicFilterType,
} from '@capturum/ui/dynamic-filters';
import { FilterMatchMode, ToastService } from '@capturum/ui/api';
import { DialogService } from 'primeng/dynamicdialog';
import { RoiDetailDialogComponent } from '@features/project/component/roi-detail-dialog/roi-detail-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { RoiMassUpdateRequestMonth } from '@features/project/interfaces/roi.interface';
import { formatDate } from '@angular/common';
import { clone } from '@capturum/builders/core';
import { AuthService } from '@shared/services/auth.service';
import { Roles } from '@core/enums/roles.enum';
import { ProjectType } from '@features/project/enums/project-type.enum';
import { Project } from '@features/project/interfaces/project.interface';
import { startOfMonth } from 'date-fns';
import { FilterConfigItem } from '@capturum/ui/dynamic-filters/interfaces/dynamic-filter.interface';

@Component({
  selector: 'app-roi-overview',
  templateUrl: './roi-overview.component.html',
  styleUrls: ['./roi-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TableService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoiOverviewComponent implements OnInit {
  @ViewChild('plannedRoiTable')
  public plannedRoiTable: Table;

  @ViewChild('dynamicFiltersComponent')
  public dynamicFiltersComponent: CapturumDynamicFiltersComponent;

  public hideSelection: boolean;
  public tableData$: Observable<Record<string, string | number | boolean>[]>;
  public tableDataOriginal: Record<string, string | number | boolean>[];
  public tableColumns$: Observable<string[]>;
  public columns: string[];
  public filterConfig: DynamicFilterConfig;
  public projectId: string;
  public isEdit = false;
  public activeFilters: ActiveFilters[] = [];
  public realizedRoiData$: Observable<Record<string, string | number | boolean>[]>;
  public loadingRealizedRoi: boolean;
  public loadingPlannedRoi: boolean;
  public userIsAccountManager: boolean;
  public showCurrency: boolean;

  private updateFilterValues = new BehaviorSubject<boolean>(true);

  constructor(
    private projectStateService: ProjectStateService,
    private projectApiService: ProjectApiService,
    private tableService: TableService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private printService: PrintService
  ) {}

  public ngOnInit(): void {
    this.userIsAccountManager = this.authService.hasRole(Roles.accountManager);

    const projectAndFilters$ = combineLatest([
      this.projectStateService.getProject().pipe(filter(Boolean)),
      this.tableService.getUpdateTable(),
    ]).pipe(
      tap(([project]) => {
        this.showCurrency = project?.projectType?.value === ProjectType.value;
        this.loadingRealizedRoi = true;
        this.loadingPlannedRoi = true;

        if (!this.filterConfig) {
          this.setFilterConfig(project);
        }
        this.projectId = project.id;
      })
    );

    this.realizedRoiData$ = projectAndFilters$.pipe(
      switchMap(([project, options]) => {
        return this.projectApiService.realizedRoi(project.id, options, this.showCurrency);
      }),
      tap(() => {
        this.loadingRealizedRoi = false;
      })
    );

    this.tableData$ = projectAndFilters$.pipe(
      switchMap(([project, options]) => {
        return this.projectApiService.getPlannedRoi(project.id, options, this.showCurrency).pipe(
          map((rows) => {
            if (
              options?.filters.some((activeFilter) => {
                return activeFilter.field === 'user_ids';
              }) ||
              this.userIsAccountManager
            ) {
              return rows.filter((_, index) => {
                return index !== 0;
              });
            }

            return rows;
          })
        );
      }),
      shareReplay(1),
      tap((rows) => {
        this.loadingPlannedRoi = false;
        this.tableDataOriginal = clone(rows);
      })
    );

    this.tableColumns$ = this.tableData$.pipe(
      map((roiData) => {
        return Object.keys(roiData[0]).filter((key) => {
          return !['property', 'underlined', 'bold', 'highlight', 'percentage', 'totalField', 'decimal'].includes(key);
        });
      })
    );
  }

  public setFilter(filters: ActiveFilters[]): void {
    this.cancelEditing();
    this.tableService.updateTableByFilters(filters);
  }

  public openRoiDialog(): void {
    const dialog = this.dialogService.open(RoiDetailDialogComponent, {
      header: this.translateService.instant('market_discovery.roi.add.button'),
      width: '80vw',
      styleClass: 'roi-dialog',
      data: {
        projectId: this.projectId,
      },
    });

    dialog.onClose.pipe(first()).subscribe((refresh) => {
      if (refresh) {
        this.tableService.refreshTable();
        this.updateFilterValues.next(true);
      }
    });
  }

  public editRoi(): void {
    if (!this.userValueIsSet()) {
      this.toastService.error(
        this.translateService.instant('market_discovery.roi.edit.title'),
        this.translateService.instant('market_discovery.roi.select-required-filters')
      );
    } else {
      const monthValues: RoiMassUpdateRequestMonth[] = this.getRoiMonthValues();

      const filters = this.activeFilters.filter((activeFilter) => {
        return ['user_ids', 'start_at'].includes(activeFilter.field);
      });

      this.projectApiService
        .validateMassUpdateRoi({
          start_date:
            filters.find((activeFilter) => {
              return activeFilter.field === 'start_at';
            })?.value || new Date().toISOString(),
          project_id: this.projectStateService.getProjectSnapshot().id,
          months: monthValues,
          user_ids: this.userIsAccountManager
            ? [this.authService.getUser().id]
            : filters.find((activeFilter) => {
                return activeFilter.field === 'user_ids';
              }).value,
        })
        .subscribe((response) => {
          if (response.is_valid) {
            this.isEdit = true;
          } else {
            this.toastService.error(this.translateService.instant('market_discovery.roi.edit.title'), response.message);
          }

          this.cdr.detectChanges();
        });
    }
  }

  public cancelEditing(): void {
    this.tableService.refreshTable();
    this.isEdit = false;
    this.cdr.detectChanges();
  }

  public submitMassUpdate(): void {
    if (this.userValueIsSet()) {
      const monthValues: RoiMassUpdateRequestMonth[] = this.getRoiMonthValues();

      const filters = this.activeFilters.filter((activeFilter) => {
        return ['user_ids', 'start_at'].includes(activeFilter.field);
      });

      this.projectApiService
        .massUpdateRoi({
          start_date:
            filters.find((activeFilter) => {
              return activeFilter.field === 'start_at';
            })?.value || new Date().toISOString(),
          project_id: this.projectStateService.getProjectSnapshot().id,
          months: monthValues,
          user_ids: this.userIsAccountManager
            ? [this.authService.getUser().id]
            : filters.find((activeFilter) => {
                return activeFilter.field === 'user_ids';
              }).value,
        })
        .subscribe(() => {
          this.cancelEditing();
          this.toastService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant('market_discovery.roi.update.message')
          );
        });
    }
  }

  public exportRoi(): void {
    this.printService.setPrintBusy(true);

    this.toastService.info(
      this.translateService.instant('market_discovery.roi.export'),
      this.translateService.instant('market_discovery.roi.export.started')
    );
    this.projectApiService
      .exportRoi(this.projectId, { filters: this.dynamicFiltersComponent.activeFilters })
      .subscribe((response) => {
        if (response?.finished) {
          this.printService.setPrintBusy(false);
        }
      });
  }

  private userValueIsSet(): boolean {
    return (
      this.userIsAccountManager ||
      this.activeFilters.some((activeFilter) => {
        return activeFilter.field == 'user_ids' && !!activeFilter.value;
      })
    );
  }

  private setFilterConfig(project: Project): void {
    let filters: FilterConfigItem[] = [];

    if (!this.userIsAccountManager) {
      filters = [
        {
          field: 'user_ids',
          showLabel: true,
          type: DynamicFilterType.multiselect,
          options: this.projectApiService.getAccountManagersList(project.id),
          icon: 'fas fa-user',
          placeholder: this.translateService.instant('market_discovery.roi.user_id.label'),
          label: this.translateService.instant('market_discovery.roi.user_id.label'),
          matchMode: FilterMatchMode.EQUALS,
          disabled: this.isEdit,
          selectedItemsLabel: '{0} items',
          maxSelectedLabels: 1,
        },
      ];
    }

    this.filterConfig = {
      filters: [
        ...filters,
        {
          field: 'start_at',
          type: DynamicFilterType.date,
          placeholder: this.translateService.instant('market_discovery.roi.starts-at.label'),
          icon: 'fas fa-calendar',
          label: this.translateService.instant('market_discovery.roi.starts-at.label'),
          matchMode: FilterMatchMode.EQUALS,
          view: 'month',
          dateFormat: 'MM - yy',
          minDate: startOfMonth(new Date(project.started_at || project.created_at)),
          showLabel: true,
          disabled: this.isEdit,
          transformValue: (value) => {
            if (value) {
              return formatDate(new Date(value), 'yyyy-MM-dd', 'en');
            }

            return null;
          },
        },
      ],
    };
  }

  private getRoiMonthValues(): RoiMassUpdateRequestMonth[] {
    return this.plannedRoiTable.value?.reduce((roiMonths, tableRow) => {
      const newRoiMonths = [...roiMonths];

      for (const key in tableRow) {
        if (
          !['property', 'title', 'underlined', 'bold', 'percentage', 'highlight', 'totalField', 'decimal'].includes(key)
        ) {
          const month = newRoiMonths.find((roiMonth) => {
            return roiMonth.key === key;
          });

          if (month) {
            month[tableRow.property] = +tableRow[key];
          } else {
            newRoiMonths.push({
              key,
              [tableRow.property]: +tableRow[key],
            });
          }
        }
      }

      return newRoiMonths;
    }, []);
  }
}

<app-page-layout [title]="'market_discovery.questionnaire.entity.name' | translate">
  <app-page-buttons actions>
    <cap-button
      *ngIf="!questionnaireDisabled?.[tabsComponent.activeTab]"
      icon="fas fa-save"
      [label]="'button.submit' | translate"
      (click)="submit()"
      styleClass="primary">
    </cap-button>

    <cap-button
      styleClass="primary"
      *ngIf="questionnaireDisabled?.[tabsComponent.activeTab]"
      [label]="'market_discovery.questionnaire.duplicate-and-edit' | translate"
      icon="fas fa-copy"
      (onClick)="duplicate()">
    </cap-button>
  </app-page-buttons>

  <div content>
    <app-tabs #tabsComponent [tabs]="tabs"></app-tabs>

    <div class="tab-questionnaire-content">
      <ng-container *ngIf="project$ | async as project">
        <div *ngFor="let tab of tabs; let index = index">
          <div [class.d-none]="tabsComponent.activeTab !== index">
            <app-questionnaire-form
              [questionnaireId]="tab.id | async"
              [contextKey]="tab.key"
              [isDmu]="index === 1"
              [disableGlobalQuestionnaire]="true"
              (questionnaireDisabled)="questionnaireDisabled[index] = $event"
              (questionChanged)="handleQuestionChanged($event, tab.key)"
              (questionsOrderChanged)="handleQuestionsOrderChanged($event, tab.key)">
            </app-questionnaire-form>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</app-page-layout>

<div class="dmu-details-grid mb-4" *ngIf="lead$.asObservable() | async as lead">
  <div class="grid-header">
    <div class="header">{{ 'market_discovery.agent-questionnaire.lead.full_name.label' | translate }}</div>
    <div class="header">{{ 'market_discovery.agent-questionnaire.lead.function.label' | translate }}</div>
  </div>

  <div class="grid-body">
    <ng-container *ngFor="let contact of lead.contacts; let index = index">
      <div class="value checkbox">
        <p-checkbox
          (onChange)="setSelectedOptions($event.checked)"
          name="checkboxGroup"
          [value]="contact.id"
          [inputId]="contact.id"
          [(ngModel)]="selectedContacts"></p-checkbox>
        <div class="checkbox-label">
          {{
            contact.salutation_base_data_value_id
              ? (contact.salutation_base_data_value_id | capBaseDataTranslation)
              : ''
          }}
          {{ contact.initials }}
          {{ contact.last_name }}
          <ng-container *ngIf="contact.is_main_contact">
            ({{ 'market_discovery.agent-questionnaire.lead.contact_person.label' | translate }})
          </ng-container>
        </div>
      </div>

      <div class="value">
        {{ contact.function_base_data_value_id ? (contact.function_base_data_value_id | capBaseDataTranslation) : '' }}
      </div>
    </ng-container>
  </div>
</div>

<div class="dmus-list" [formGroup]="formGroup">
  <ng-container formArrayName="contacts">
    <div class="container-fluid dmus-list-item" *ngFor="let dmu of contacts.controls; index as i">
      <ng-container [formGroupName]="i">
        <div class="remove-btn mb-3">
          <span (click)="remove(i)"> <i class="fas fa-trash-can"></i> {{ 'button.delete' | translate }} </span>
        </div>

        <div class="row mb-3">
          <div class="col-md-3">
            <label>{{ 'market_discovery.agent-questionnaire.lead.function.label' | translate }} <span>*</span></label>
            <cap-dropdown
              formControlName="function_base_data_value_id"
              [options]="functionOptions$ | async"
              [autoDisplayFirst]="false"
              [filter]="true"
              [placeholder]="'placeholder.select' | translate">
            </cap-dropdown>
          </div>

          <div class="col-md-3">
            <label>{{ 'market_discovery.lead.contact.salutation.label' | translate }} <span>*</span></label>
            <cap-dropdown
              formControlName="salutation_base_data_value_id"
              [options]="salutationOptions$ | async"
              [autoDisplayFirst]="false"
              [placeholder]="'placeholder.select' | translate">
            </cap-dropdown>
          </div>

          <div class="col-md-3">
            <label>{{ 'market_discovery.lead.contact.initials.label' | translate }} <span>*</span></label>
            <cap-input formControlName="initials"></cap-input>
          </div>

          <div class="col-md-3">
            <label>{{ 'market_discovery.lead.contact.first_name.label' | translate }}</label>
            <cap-input formControlName="first_name"></cap-input>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-3">
            <label>{{ 'market_discovery.lead.contact.last_name.label' | translate }} <span>*</span></label>
            <cap-input formControlName="last_name"></cap-input>
          </div>

          <div class="col-md-3">
            <label>{{ 'market_discovery.lead.address.phone.label' | translate }}</label>
            <cap-input formControlName="phone"></cap-input>
          </div>

          <div class="col-md-3">
            <label>{{ 'market_discovery.lead.address.mobile_phone.label' | translate }}</label>
            <cap-input formControlName="mobile_phone"></cap-input>
          </div>

          <div class="col-md-3">
            <label>{{ 'market_discovery.lead.contact.email.label' | translate }}</label>
            <cap-input formControlName="email"></cap-input>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-3">
            <label>{{ 'market_discovery.lead.contact.gender.label' | translate }} <span>*</span></label>
            <cap-dropdown
              [options]="genderOptions$ | async"
              formControlName="gender_base_data_value_id"
              [autoDisplayFirst]="false"
              [placeholder]="'placeholder.select' | translate">
            </cap-dropdown>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <cap-button icon="fas fa-plus" [label]="'button.new' | translate" styleClass="primary" (onClick)="add()">
  </cap-button>
</div>

<app-page-layout
  [title]="(lead$ | async)?.name"
  [backRoute]="backRoute"
  (handlePrintCommand)="exportLead()"
  [showPrintButton]="true">
  <app-page-buttons actions>
    <cap-button
      icon="far fa-arrow-left"
      [label]="'market_discovery.project.leads.previous.label' | translate"
      [disabled]="isNavigating || (isFirst | async)"
      (onClick)="previous()"
      styleClass="secondary">
    </cap-button>

    <cap-button
      icon="far fa-arrow-right"
      iconPos="right"
      [label]="'market_discovery.project.leads.next.label' | translate"
      [disabled]="isNavigating || (isLast | async)"
      (onClick)="next()"
      styleClass="primary">
    </cap-button>
  </app-page-buttons>

  <div content class="lead-detail row">
    <div class="col-lg-6">
      <cap-card [title]="'market_discovery.lead.general-info.label' | translate" styleClass="mb-4">
        <ng-template capTemplate="content">
          <div class="card-buttons">
            <cap-button
              class="card-button"
              icon="fa-brands fa-google"
              styleClass="secondary round"
              (onClick)="searchGoogle()"
              [label]="'market_discovery.button.search' | translate">
            </cap-button>

            <cap-button
              class="card-button"
              *ngxPermissionsOnly="['lead.manage']"
              [routerLink]="['edit']"
              queryParamsHandling="merge"
              styleClass="primary round"
              [label]="'button.edit' | translate"
              icon="fas fa-pencil">
            </cap-button>
          </div>

          <div [hidden]="loadingData">
            <cpb-form-renderer
              [showHeader]="false"
              [context]="{ project_id: projectId }"
              [readOnly]="true"
              formKey="form_lead_general"
              [modelId]="leadId"></cpb-form-renderer>
          </div>

          <div class="skeletons" *ngIf="loadingData">
            <cap-skeleton width="70%" height="15px"></cap-skeleton>
            <cap-skeleton width="60%" height="15px"></cap-skeleton>
            <cap-skeleton width="60%" height="15px"></cap-skeleton>
            <cap-skeleton width="60%" height="15px"></cap-skeleton>
            <cap-skeleton width="70%" height="15px"></cap-skeleton>
            <cap-skeleton width="60%" height="15px"></cap-skeleton>
            <cap-skeleton width="60%" height="15px"></cap-skeleton>
            <cap-skeleton width="65%" height="15px"></cap-skeleton>
            <cap-skeleton width="70%" height="15px"></cap-skeleton>
            <cap-skeleton width="60%" height="15px"></cap-skeleton>
            <cap-skeleton width="65%" height="15px"></cap-skeleton>
          </div>
        </ng-template>
      </cap-card>

      <cap-card [title]="'market_discovery.lead.project_info.label' | translate" class="project-details">
        <ng-template capTemplate="content">
          <div class="card-buttons">
            <cap-button
              class="card-button"
              [routerLink]="['project-information']"
              styleClass="primary round"
              queryParamsHandling="merge"
              *ngxPermissionsOnly="['lead.manage']"
              [label]="'button.edit' | translate"
              icon="fas fa-pencil">
            </cap-button>
          </div>

          <div [hidden]="loadingMetaData">
            <app-project-info-meta [leadMetaKeys]="leadMetaKeys$ | async"></app-project-info-meta>
          </div>
          <div class="skeletons" *ngIf="loadingMetaData">
            <cap-skeleton width="70%" height="15px"></cap-skeleton>
            <cap-skeleton width="60%" height="15px"></cap-skeleton>
            <cap-skeleton width="65%" height="15px"></cap-skeleton>
          </div>
        </ng-template>
      </cap-card>
    </div>

    <div class="col-lg-6">
      <cap-card [title]="'market_discovery.lead.account_manager_id.label' | translate" class="account-manager">
        <ng-template capTemplate="content">
          <div class="lead-cards">
            <div class="card-section mb-4">
              <div class="card-buttons">
                <cap-button
                  styleClass="primary round"
                  icon="fas fa-pencil"
                  queryParamsHandling="merge"
                  *ngxPermissionsOnly="['lead.manage']"
                  [label]="'button.edit' | translate"
                  [routerLink]="['account-manager']">
                </cap-button>
              </div>

              <div [hidden]="loadingAccountManagerData || accountManagerContext == null">
                <cpb-form-renderer
                  formKey="form_lead_accountmanager"
                  [modelId]="leadId"
                  [readOnly]="true"
                  [context]="accountManagerContext"
                  [showHeader]="false">
                </cpb-form-renderer>
              </div>

              <div class="skeletons" *ngIf="loadingAccountManagerData">
                <cap-skeleton width="60%" height="15px"></cap-skeleton>
                <cap-skeleton width="70%" height="15px"></cap-skeleton>
                <cap-skeleton width="65%" height="15px"></cap-skeleton>
              </div>
            </div>

            <div class="card-section mb-4">
              <app-lead-grip-input
                [leadId]="leadId"
                [leadAccountManager]="leadAccountManager$ | async"></app-lead-grip-input>
            </div>

            <div class="card-section mb-4">
              <app-lead-actions
                [hidden]="loadingAccountManagerData"
                [lead]="leadAccountManager$ | async"></app-lead-actions>

              <div class="skeletons mt-2" *ngIf="loadingAccountManagerData">
                <cap-skeleton width="70%" height="15px"></cap-skeleton>
                <cap-skeleton width="70%" height="15px"></cap-skeleton>
                <cap-skeleton width="70%" height="15px"></cap-skeleton>
              </div>
            </div>

            <div class="card-section mb-4">
              <app-lead-attachments [leadId]="leadId" [leadFiles]="(lead$ | async)?.files"></app-lead-attachments>
            </div>

            <div class="card-section">
              <app-lead-history
                [lead]="leadAccountManager$ | async"
                [hidden]="loadingAccountManagerData"></app-lead-history>
            </div>

            <div class="skeletons mt-2" *ngIf="loadingAccountManagerData">
              <cap-skeleton width="70%" height="15px"></cap-skeleton>
              <cap-skeleton width="70%" height="15px"></cap-skeleton>
              <cap-skeleton width="70%" height="15px"></cap-skeleton>
              <cap-skeleton width="70%" height="15px"></cap-skeleton>
            </div>
          </div>
        </ng-template>
      </cap-card>
    </div>
  </div>
</app-page-layout>

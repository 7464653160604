import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '@capturum/auth';
import { BaseDataKeyService } from '@capturum/complete';
import { MapItem } from '@capturum/ui/api';
import { Contact } from '@core/interfaces/contact.interface';
import { Lead } from '@features/lead/interfaces/lead.interface';
import {
  Question,
  QuestionInputTypeComponent,
  UpdateAnswer,
} from '@features/questionnaire/interfaces/questionnaire.interface';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

interface DmuAnswer {
  contact_ids: string[];
  contacts?: Partial<Contact>[];
}

interface DmuAnswerFormGroup {
  contact_ids: FormControl<string[]>;
  contacts: FormArray<FormGroup<QuestionnaireContactFormGroup>>;
}

interface QuestionnaireContactFormGroup {
  function_base_data_value_id: FormControl<string>;
  salutation_base_data_value_id: FormControl<string>;
  initials: FormControl<string>;
  first_name: FormControl<string>;
  last_name: FormControl<string>;
  phone: FormControl<string>;
  mobile_phone: FormControl<string>;
  email: FormControl<string>;
  gender_base_data_value_id: FormControl<string>;
}

@Component({
  selector: 'app-question-input-type-dmus',
  templateUrl: './dmus-input-type.component.html',
  styleUrls: ['./dmus-input-type.component.scss'],
})
export class QuestionInputTypeDmusComponent implements QuestionInputTypeComponent<DmuAnswer>, OnInit, OnDestroy {
  @Input()
  public question: Question;

  @Output()
  public updateAnswer: EventEmitter<UpdateAnswer> = new EventEmitter<UpdateAnswer>();

  public formGroup: FormGroup<DmuAnswerFormGroup>;
  public answer$: BehaviorSubject<DmuAnswer> = new BehaviorSubject<DmuAnswer>(null);
  public functionOptions$: Observable<MapItem[]>;
  public salutationOptions$: Observable<MapItem[]>;
  public genderOptions$: Observable<MapItem[]>;
  public selectedContacts: string[];
  public questionRequired = false;
  public lead$: BehaviorSubject<Lead> = new BehaviorSubject<Lead>(null);

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private baseDataKeyService: BaseDataKeyService,
    private authService: AuthService
  ) {
    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
  }

  public get contacts(): FormArray<FormGroup<QuestionnaireContactFormGroup>> {
    return this.formGroup?.get('contacts') as FormArray<FormGroup<QuestionnaireContactFormGroup>>;
  }

  public get contactIds(): FormControl<string[]> {
    return this.formGroup?.get('contact_ids') as FormControl<string[]>;
  }

  public ngOnInit(): void {
    this.formGroup = this.generateFormGroup();
    this.functionOptions$ = this.baseDataKeyService.getBaseDataKeyValues('contact_function');
    this.salutationOptions$ = this.baseDataKeyService.getBaseDataKeyValues('salutation');
    this.genderOptions$ = this.baseDataKeyService.getBaseDataKeyValues('gender');

    this.answer$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value?.contacts?.length) {
          const newContacts = value.contacts.filter((contact) => {
            return !contact.lead_id;
          });

          if (newContacts.length) {
            this.contacts.clear();

            newContacts.forEach((contact, index) => {
              this.add();

              this.contacts.at(index).patchValue(contact);
            });
          }

          const contactIds = value.contacts
            .filter((contact) => {
              return !!contact.lead_id;
            })
            .map((contact) => {
              return contact.id;
            });

          this.formGroup.patchValue({
            contact_ids: contactIds,
          });

          this.selectedContacts = contactIds;
        } else if (value) {
          this.formGroup.patchValue(value);
          this.selectedContacts = value.contact_ids;
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public validate(): boolean {
    let valid = true;

    if (this.questionRequired || this.question.is_required) {
      valid = !!this.formGroup.get('contact_ids').value.length || !!this.contacts.length;

      if (this.contacts.length) {
        valid = this.contacts.controls.every((dmuControl) => {
          return dmuControl.valid;
        });
      }
    }

    if (valid) {
      const value = this.formGroup.value;

      if (value.contacts) {
        value.contacts = value.contacts.filter(Boolean);
      }

      if (value.contact_ids) {
        value.contact_ids = value.contact_ids.filter(Boolean);
      }

      this.updateAnswer.emit({
        id: this.question.id,
        value: value,
      });
    }

    return valid;
  }

  public add(): void {
    if (!this.question.is_required) {
      this.questionRequired = true;
    }

    this.contacts.push(
      this.formBuilder.group({
        function_base_data_value_id: ['', Validators.required],
        salutation_base_data_value_id: ['', Validators.required],
        initials: ['', Validators.required],
        first_name: [''],
        last_name: ['', Validators.required],
        phone: [''],
        mobile_phone: [''],
        email: [''],
        gender_base_data_value_id: ['', Validators.required],
      })
    );

    this.generateFormGroup();
  }

  public remove(index: number): void {
    this.contacts.removeAt(index);

    if (this.contacts.value.length === 0 && !this.question.is_required) {
      this.questionRequired = false;
    }
  }

  public getAnswer(): DmuAnswer {
    return {
      contact_ids: this.formGroup.value.contact_ids,
      contacts: this.formGroup.value.contacts,
    };
  }

  public setSelectedOptions(contactIds: string[]): void {
    this.contactIds.patchValue(contactIds);
  }

  private generateFormGroup(): FormGroup {
    return new FormGroup({
      contact_ids: new FormControl([]),
      contacts:
        this.questionRequired || this.question.is_required ? new FormArray([], Validators.required) : new FormArray([]),
    });
  }
}

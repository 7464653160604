import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { ProjectHour } from '@features/project-hour/interfaces/project-hour.interface';
import { map, Observable, switchMap } from 'rxjs';
import { BatchStatusService, FinishedBatchStatus } from '@capturum/complete';
import { responseData } from '@capturum/builders/core';

@Injectable({ providedIn: 'root' })
export class ProjectHourApiService extends ApiService<ProjectHour> {
  protected endpoint = 'project-hour';

  constructor(apiHttp: ApiHttpService, private batchStatusService: BatchStatusService) {
    super(apiHttp);
  }

  public getTotal(options: ListOptions): Observable<string> {
    return this.apiHttp.get<{ total: string }>(`/${this.endpoint}/total${ApiService.generateQuery(options)}`).pipe(
      map((response) => {
        return response.total;
      })
    );
  }

  public exportToPdf(options?: ListOptions): Observable<FinishedBatchStatus> {
    return this.apiHttp
      .get<{ data: { batch_id: string } }>(`/${this.endpoint}/export${this.getOptionsQuery(options)}`)
      .pipe(
        responseData,
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.batch_id,
            true,
            'market_discovery.project-hours.export.success',
            true
          );
        })
      );
  }

  public exportToExcel(options?: ListOptions, projectId?: string): Observable<FinishedBatchStatus> {
    const url: string = projectId
      ? `/project/${projectId}/${this.endpoint}/export-excel${this.getOptionsQuery(options)}`
      : `/${this.endpoint}/export-excel${this.getOptionsQuery(options)}`;

    return this.apiHttp.get(url).pipe(
      switchMap((response: any) => {
        return this.batchStatusService.getIsUpdatedBatch(
          response?.batch_id,
          true,
          'market_discovery.project-hours.export.success',
          true
        );
      })
    );
  }
}

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { saveAs } from 'file-saver';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-lead-action-detail',
  templateUrl: './lead-action-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LeadActionDetailComponent implements OnInit {
  public context: { isEdit: boolean; lead_id: string; id: string };
  public actionId: string;
  public isReadonly: boolean;
  public defaultValue: { due_at: string; ics_summary: string; is_send_to_user: boolean };
  private preventDownload: boolean;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogReference: DynamicDialogRef,
    private formSaverService: FormSaverService,
    private formRendererService: FormRendererService
  ) {}

  public ngOnInit(): void {
    const lead = this.dialogConfig.data?.lead;

    this.actionId = this.dialogConfig.data.id;
    this.context = { isEdit: !!this.actionId, lead_id: this.dialogConfig?.data?.lead?.id, id: this.actionId };
    this.isReadonly = this.dialogConfig.data.readonly;
    this.defaultValue = {
      due_at: this.dialogConfig.data?.lead?.target_at || new Date(),
      is_send_to_user: false,
      ics_summary: `${lead.status.value}: ${lead.name}`,
    };

    if (this.actionId) {
      this.formRendererService
        .getSourceValueByKey('form_action')
        .pipe(filter(Boolean), first())
        .subscribe((value) => {
          this.preventDownload = value.is_send_to_user;
        });
    }
  }

  public submit(): void {
    this.formSaverService.submit('form_action').subscribe(({ response }) => {
      if (response.data.ics_data && !this.preventDownload) {
        const blob = new Blob([response.data.ics_data.data], { type: 'application/octet-stream' });

        saveAs(blob, response.data.ics_data.fileName);
      }

      this.dialogReference.close(response.data);
    });
  }

  public cancel(): void {
    this.dialogReference.close();
  }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { FieldArrayType, FieldArrayTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-meta-value-option-type',
  templateUrl: './meta-value-option-type.component.html',
  styleUrls: ['./meta-value-option-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MetaValueOptionTypeComponent extends FieldArrayType {
  public formGroup: UntypedFormGroup;

  public onPopulate(field: FieldArrayTypeConfig<FormlyFieldProps & { [additionalProperties: string]: any }>): void {
    field.fieldArray = {
      fieldGroup: [
        {
          key: 'name',
          type: 'input',
          defaultValue: null,
        },
        {
          key: 'id',
          type: 'input',
          className: 'd-none',
          defaultValue: null,
        },
      ],
    };

    super.onPopulate(field);
  }
}

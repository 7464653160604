import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface LeadDetailsState {
  lead_status_base_data_value_id: string;
  remarks: string;
}

@Injectable({
  providedIn: 'root',
})
export class AgentQuestionnaireStateService {
  private leadDetails = new BehaviorSubject<LeadDetailsState>({
    lead_status_base_data_value_id: null,
    remarks: null,
  });

  public getLeadDetailsSnapshot(): LeadDetailsState {
    return this.leadDetails.getValue();
  }

  public setLeadDetails(leadDetails: LeadDetailsState): void {
    this.leadDetails.next(leadDetails);
  }
}

import { Component } from '@angular/core';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project-selection-letter-dialog',
  templateUrl: './project-selection-letter-dialog.component.html',
  styleUrls: ['./project-selection-letter-dialog.component.scss'],
})
export class ProjectSelectionLetterDialogComponent {
  public formKey = 'form_project_selection_letter';
  public defaultValue = { letterData: { letter_date: new Date() } };

  constructor(
    private formSaver: FormSaverService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  public submit(): void {
    const additionalData = {
      lead_ids: this.dialogConfig.data.item.map((item) => {
        return item.id;
      }),
    };

    this.formSaver.submit(this.formKey, additionalData).subscribe(() => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('market_discovery.letter.action.success')
      );
      this.dialogRef.close();
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}

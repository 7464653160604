import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { LeadActionDetailComponent } from '@features/project/components/lead-action-detail/lead-action-detail.component';
import { LeadAction } from '@features/project/interfaces/lead-action.interface';
import { TranslateService } from '@ngx-translate/core';
import { compareDesc } from 'date-fns';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { LeadDetails } from '../../interfaces/lead-details.interface';

@Component({
  selector: 'app-lead-actions',
  templateUrl: './lead-actions.component.html',
  styleUrls: ['./lead-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LeadActionsComponent {
  public completedActions$: Observable<LeadAction[]>;
  public openActions$: Observable<LeadAction[]>;
  private updateActions = new BehaviorSubject<boolean>(true);

  constructor(
    private leadService: LeadApiService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  private _lead: LeadDetails;

  @Input()
  public set lead(value: LeadDetails) {
    if (value) {
      this._lead = value;

      this.loadLeadActions();
    }
  }

  public editAction(action: LeadAction): void {
    this.openLeadActionDetailDialog(action);
  }

  public addAction(): void {
    this.openLeadActionDetailDialog();
  }

  private loadLeadActions(): void {
    const actions$ = this.updateActions.asObservable().pipe(
      switchMap(() => {
        return this.leadService.getActions(this._lead.id).pipe(
          map((actions) => {
            const sortedActionsDates = [...actions].sort((a, b) => {
              return compareDesc(new Date(a.due_at), new Date(b.due_at));
            });

            return sortedActionsDates;
          })
        );
      }),
      shareReplay(1)
    );

    this.completedActions$ = actions$.pipe(
      map((actions) => {
        return actions?.filter((action) => {
          return action.is_completed;
        });
      })
    );

    this.openActions$ = actions$.pipe(
      map((actions) => {
        return actions?.filter((action) => {
          return !action.is_completed;
        });
      })
    );
  }

  private openLeadActionDetailDialog(action?: LeadAction): void {
    const title = action ? 'market_discovery.form.edit.title' : 'market_discovery.form.add.title';

    const dialog = this.dialogService.open(LeadActionDetailComponent, {
      header: this.translateService.instant(title, {
        entity: this.translateService.instant('market_discovery.action.entity.name'),
      }),
      styleClass: 'lead-action-detail-dialog',
      data: {
        lead: this._lead,
        id: action?.id,
        readonly: action?.is_completed,
      },
      contentStyle: {
        'overflow-y': 'visible', // Necessary for displaying calendar popup correctly
      },
    });

    dialog?.onClose.subscribe((result) => {
      if (result) {
        this.updateActions.next(true);
      }
      dialog.destroy();
    });
  }
}

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CapturumFormRendererComponent, FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { BatchStatusService } from '@capturum/complete';
import { ToastService } from '@capturum/ui/api';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-lead-import',
  templateUrl: './lead-import.component.html',
  styleUrls: ['./lead-import.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeadImportComponent implements OnInit {
  @ViewChild(CapturumFormRendererComponent)
  public formRenderer: CapturumFormRendererComponent;

  public formKey = 'form_import_leads';
  public importBusy$: Observable<boolean>;
  public fileErrors: string;

  protected entity = 'lead';
  protected importAsSelection = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private formRendererService: FormRendererService,
    private projectStateService: ProjectStateService,
    private leadApiService: LeadApiService,
    private batchStatusService: BatchStatusService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private formSaver: FormSaverService
  ) {}

  public ngOnInit(): void {
    this.importBusy$ = this.projectStateService.getLeadsImportBusy();
  }

  public submit(): void {
    this.fileErrors = null;
    const progress: number = null;

    combineLatest([
      this.formRendererService.getFormValueByKey(this.formKey).pipe(first()),
      this.projectStateService.getProject().pipe(filter(Boolean), first()),
    ])
      .pipe(
        first(),
        map(([formValue, project]) => {
          return {
            lead_source_base_data_value_id: formValue.lead_source_base_data_value_id,
            overwrite_all: formValue.overwrite_all,
            source: formValue.source,
            selection_name: formValue.selection_name,
            file: formValue.file
              ? {
                  filename: formValue?.file?.filename,
                  uuid: formValue?.file?.data,
                  project_id: project.id,
                }
              : null,
          };
        }),
        tap(() => {
          if (!this.formRenderer.form.valid) {
            return;
          }

          this.toastService.info(
            this.translateService.instant(`market_discovery.${this.entity}.import.title`),
            this.translateService.instant(`market_discovery.${this.entity}.import.start`)
          );
          this.projectStateService.setLeadsImportBusy(true);
          this.navigateBack();
        }),
        switchMap((requestBody) => {
          return this.leadApiService.import(requestBody, this.importAsSelection).pipe(
            map((response) => {
              return {
                ...response,
                overwriteAll: requestBody.overwrite_all,
              };
            })
          );
        }),
        switchMap(({ batch_id, overwriteAll }) => {
          return this.batchStatusService.getIsUpdatedBatch(
            batch_id,
            true,
            `market_discovery.${this.entity}.import.success`,
            !overwriteAll
          );
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response.finished) {
            this.projectStateService.setLeadsImportBusy(false);
          }

          if (response.message?.data?.errors) {
            const message = (Object.values(response.message.data.errors)[0] as string)
              .split(`ApiException:`)[1]
              .split(`in /var/`)[0]
              .trim();

            this.toastService.error(this.translateService.instant('toast.error.title'), message, { sticky: true });
          }
        },
        error: ({ error }) => {
          if (error.message) {
            this.fileErrors = error.message;
          }

          this.formSaver.markFormAsSubmitted(this.formKey);
          this.formSaver.setValidationErrors(error, this.formKey);
          this.projectStateService.setLeadsImportBusy(false);
        },
      });
  }

  protected navigateBack(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}

<app-page-layout
  [title]="titleTranslationKey | translate : { entity: ('market_discovery.' + entity + '.entity.name' | translate) }"
  backRoute="../">
  <app-page-buttons actions>
    <cap-button icon="fas fa-xmark" [label]="'button.cancel' | translate" (click)="cancel()" styleClass="secondary">
    </cap-button>

    <cap-button icon="fas fa-save" [label]="'button.submit' | translate" (click)="submit()" styleClass="primary">
    </cap-button>
  </app-page-buttons>

  <app-questionnaire-form
    content
    [questionnaireId]="modelId"
    (questionChanged)="questions = $event"
    (questionsOrderChanged)="handleQuestionsOrderChanged($event)"></app-questionnaire-form>
</app-page-layout>

import { ChangeDetectorRef, Component, EventEmitter, Input } from '@angular/core';
import { DynamicFilterComponent } from '@capturum/ui/dynamic-filters';
import { FilterConfigItem } from '@capturum/ui/dynamic-filters/interfaces/dynamic-filter.interface';
import { FormControl } from '@angular/forms';
import { startWith, Subject, takeUntil } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
})
export class InputFilterComponent implements DynamicFilterComponent<string> {
  @Input()
  public filterConfig: FilterConfigItem;

  public updateValue: EventEmitter<string> = new EventEmitter<string>();
  public formControl = new FormControl();
  public value: string;

  private lastUpdatedValue: string;

  private destroy$ = new Subject<boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        debounceTime(this.filterConfig.debounceTime || 300),
        startWith(this.formControl.value),
        filter((value) => {
          return value !== this.lastUpdatedValue;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.updateFilterValue(value);
        this.lastUpdatedValue = value;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public updateFilterValue(value: string): void {
    this.updateValue.emit(value);
  }

  public setValue(value: string): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.lastUpdatedValue = value;
    this.cdr.detectChanges();
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.setValue(null);
  }
}

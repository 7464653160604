import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Mutation } from '../../interfaces/mutation.interface';
import { MutationType } from '../../enums/mutation-type.enum';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MutationApiService } from '../../services/mutation-api.service';
import { ListOptions } from '@capturum/api';
import { AddressInputTypeComponent } from '@features/questionnaire/components/question-input-types/address-input-type/address-input-type.component';
import { ContactInputTypeComponent } from '@features/questionnaire/components/question-input-types/contact-input-type/contact-input-type.component';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { QuestionType } from '../../../questionnaire/enums/question-type.enum';
import { FormRendererService } from '@capturum/builders/form-renderer';
import { filter, isObservable, map, Observable, of, switchMap } from 'rxjs';
import { first } from 'rxjs/operators';
import { NotificationService } from '@shared/services/notification.service';

interface MutationFormGroup {
  company_name: FormControl<string>;
}

@Component({
  selector: 'app-mutation-dialog',
  templateUrl: './mutation-dialog.component.html',
  styleUrls: ['./mutation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MutationDialogComponent implements OnInit {
  @ViewChild('addressInputType')
  public addressInputType: AddressInputTypeComponent;

  @ViewChild('contactInputType')
  public contactInputType: ContactInputTypeComponent;

  public mutation: Mutation;
  public mutationType = MutationType;
  public formGroup: FormGroup<MutationFormGroup>;
  public contactMutationTypes = [MutationType.contact, MutationType.newContact, MutationType.newDmu, MutationType.dmu];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogConfig2: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private mutationService: MutationApiService,
    private cdr: ChangeDetectorRef,
    private actionService: CapturumBuilderActionService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private formRendererService: FormRendererService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.buildForm();
    this.getMutation();
  }

  public buildForm(): void {
    this.formGroup = this.formBuilder.group({
      company_name: [''],
    });
  }

  public getMutation(): void {
    const mutationId = this.dialogConfig.data?.item?.id as string;
    const options: ListOptions = {
      include: ['type', 'lead.mailAddresses', 'lead.company', 'lead.companyAddresses', 'newAddress.type', 'newContact'],
    };

    this.mutationService.get(mutationId, options).subscribe({
      next: (mutation) => {
        if (mutation.type.value === MutationType.contact || mutation.type.value === MutationType.newContact) {
          if (mutation.newContact) {
            mutation.newContact.is_main_contact = true;
          } else {
            mutation.newContact.is_main_contact = false;
          }
        }

        this.mutation = mutation;

        this.cdr.detectChanges();

        this.afterGetEntity();
      },
    });
  }

  public afterGetEntity(): void {
    const type =
      this.mutation.newAddress?.type?.value === 'company'
        ? QuestionType.VISITING_ADDRESS
        : QuestionType.MAILING_ADDRESS;

    switch (this.mutation.type.value) {
      case MutationType.address:
        this.addressInputType.question = { type: { value: type } as any } as any;

        this.addressInputType.lead$.next(this.mutation.lead);
        this.addressInputType.showAddressForm = false;
        this.addressInputType.formGroup.patchValue({ address_id: this.mutation.newAddress.id });

        break;
      case MutationType.companyName:
        this.formGroup.patchValue(this.mutation);

        break;

      default:
        break;
    }

    this.cdr.detectChanges();
  }

  public submit(): void {
    this.getPostData()
      .pipe(
        switchMap((postData) => {
          return this.mutationService.update(postData);
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant('market_discovery.entity.toast.updated')
          );

          this.actionService.broadcastActionExecuted(
            {
              key: 'edit',
              type: null,
              options: null,
            },
            null,
            null
          );

          this.dialogRef.close(false);
          this.notificationService.refreshOpenMutationsCount();
        },
      });
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  protected getPostData(): Observable<Partial<Mutation>> {
    let postData: Partial<Mutation> | Observable<Partial<Mutation>> = {
      id: this.mutation.id,
    };

    switch (this.mutation.type.value) {
      case MutationType.address:
        postData.new_address_id = this.addressInputType.formGroup.value.address_id;

        break;
      case MutationType.companyName:
        postData.company_name = this.formGroup.value.company_name;

        break;
      case MutationType.newAddress:
        postData = this.formRendererService.getFormValueByKey('form_lead_address').pipe(
          filter(Boolean),
          first(),
          map((formValue) => {
            return {
              id: this.mutation.id,
              newAddress: formValue,
            };
          })
        );

        break;
      case MutationType.newContact:
      case MutationType.newDmu:
      case MutationType.contact:
        postData = this.formRendererService.getFormValueByKey('form_lead_contact').pipe(
          filter(Boolean),
          first(),
          map((formValue) => {
            return {
              id: this.mutation.id,
              newContact: formValue,
            };
          })
        );

        break;

      default:
        break;
    }

    return isObservable(postData) ? postData : of(postData);
  }
}

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { ProjectHour } from '@features/project-hour/interfaces/project-hour.interface';
import { FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter, first, map } from 'rxjs/operators';
import { ProjectStateService } from '../../../project/services/project-state.service';
import { Project } from '../../../project/interfaces/project.interface';
import { DestroyBase } from '@capturum/shared';
import { NgxPermissionsService } from 'ngx-permissions';

interface ProjectHourContext {
  project_id?: string;
  isEdit: boolean;
  projectHourId?: string;
}

@Component({
  selector: 'app-project-hour-dialog',
  templateUrl: './project-hour-dialog.component.html',
  styleUrls: ['./project-hour-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectHourDialogComponent extends DestroyBase implements OnInit {
  public modelId: string;
  public formValue$: Observable<ProjectHour>;
  public formKey = 'form_project-hours';
  public defaultValue$: Observable<{ project_id: string }>;
  public context$: Observable<ProjectHourContext>;

  constructor(
    private formRendererService: FormRendererService,
    private dialogConfig: DynamicDialogConfig,
    private formSaverService: FormSaverService,
    private dialogRef: DynamicDialogRef,
    private projectStateService: ProjectStateService,
    private ngxPermissionService: NgxPermissionsService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modelId = this.dialogConfig.data?.item.id;
    this.context$ = this.projectStateService.getProject().pipe(
      map((project: Project) => {
        let context: ProjectHourContext = { isEdit: true, projectHourId: null };

        if (project) {
          if (this.ngxPermissionService.getPermission('project-hour.manage')) {
            context = {
              ...context,
              project_id: project.id,
            };
          }
        }

        if (this.modelId) {
          context = {
            ...context,
            projectHourId: this.modelId,
          };
        }

        return context;
      }),
      takeUntil(this.destroy$)
    );

    this.modelId = this.dialogConfig.data?.item.id;
    if (!this.modelId) {
      this.defaultValue$ = this.projectStateService.getProject().pipe(
        filter(Boolean),
        first(),
        map((project) => {
          return {
            project_id: project.id,
            from: null,
            to: null,
          };
        })
      );
    }

    this.formValue$ = this.formRendererService.getFormValueStreamByKey(this.formKey);
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
